import React, { useState } from "react";
import Pagination from "../../utils/Pagination";
import { useQuery } from "@tanstack/react-query";
import { getNotification } from "../../services/services";
import { constant } from "../../utils/constants";
import moment from "moment";

const Notifications = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(constant.PAGE_NO_ONE);

  const { data, refetch } = useQuery({
    queryKey: ["client-list", page],
    queryFn: async () => {
      let params = {
        page: page,
        limit: constant.PAGE_LIMIT,
        sortBy: constant.SORT_BY.DESC,
        sortedBy: "createdAt",
      };
      if (search) params.search = search;
      let resp = await getNotification(params);
      return resp?.data?.data ?? {};
    },
  });

  return (
    <div className="main-content">
      <div className="notification">
        <div className="text-start m-3"><h3>Notifications</h3></div>
        <div className="row">
        {data?.notifications?.length > 0 ? (
                data.notifications.map((res, index) => (
          <div className="col-md-12 mb-3" key={index}>
            <div className={"notificationCard notificationArea"}>            
                  <div  className="notificationtext">
                    <h6 className="heading14">{res?.description || "N/A"}</h6>
                    <p className="text12 mb-0">
                      {moment(res?.createdAt).fromNow() || "Unknown time"}
                    </p>
                  </div>
             
            </div>
           
          </div>   ))
        ) : (
          ""
        )}
          {data?.notifications?.length === 0 ? (
              <p className="no-notifications">No Notifications Yet</p>
            ) : (
              ""
            )}
        </div>

        {/* Pagination Section */}
        {data?.notifications?.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={data?.total_pages}
          />
        )}
      </div>
    </div>
  );
};

export default Notifications;
