import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCamera } from "react-icons/fa6";
import * as yup from "yup";
import * as images from "../../assets/image";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { updateUser, uploadFile } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { getDaysString, setTimeFromString } from "../../utils/function";
import moment from "moment";

const EditProfile = () => {
  const role = useRole();
  const details = useDetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      profilePhoto: "",
      availability: [
        {
          day: 0,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 1,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 2,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 3,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 4,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 5,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 6,
          status: false,
          startTime: "",
          endTime: "",
        },
      ],
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required().label("First Name").trim(),
      lastName: yup.string().required().label("Last Name").trim(),
      availability: yup.array().when("role", {
        is: () => role == constant.ROLE.EMPLOYEE,
        then: () =>
          yup
            .array()
            .of(
              yup.object().shape({
                startTime: yup.string().when("status", {
                  is: (value) => value,
                  then: () => yup.string().required("Start time is required"),
                  otherwise: () => yup.string().notRequired(),
                }),
                endTime: yup.string().when("status", {
                  is: (value) => value,
                  then: () =>
                    yup
                      .string()
                      .required("End time is required")
                      .matches(
                        /^([01]\d|2[0-3]):([0-5]\d)$/,
                        "End time must be in HH:mm format"
                      )
                      .test(
                        "is-greater-than-startTime",
                        "End time must be later than start time",
                        function (value) {
                          const { startTime } = this.parent;
                          if (!startTime || !value) return true; // Skip validation if either time is missing
                          const [startHour, startMinute] = startTime
                            .split(":")
                            .map(Number);
                          const [endHour, endMinute] = value
                            .split(":")
                            .map(Number);

                          const startTotalMinutes =
                            startHour * 60 + startMinute;
                          const endTotalMinutes = endHour * 60 + endMinute;

                          return endTotalMinutes > startTotalMinutes;
                        }
                      ),
                  otherwise: () => yup.string().notRequired(),
                }),
              })
            )
            .test(
              "at-least-one-status-true",
              "At least one day must be active",
              (availability) => {
                if (!availability || !Array.isArray(availability)) return false;
                return availability.some((day) => day.status === true);
              }
            ),
      }),
    }),
    onSubmit: (values) => {
      let body = {
        ...values,
        role: details?.role,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
      };

      if (values?.profilePhoto == "" || !values?.profilePhoto) {
        delete body.profilePhoto;
      }

      /*************************For Admin*****************/
      if (role == constant.ROLE.ADMIN) {
        // delete body.email;
        delete body.availability;
        delete body.authorizedEmployees;
      }

      /*************************For Employee*****************/
      if (role == constant.ROLE.EMPLOYEE) {
        delete body.workingDays;
        delete body.authorizedEmployees;

        body.availability = values?.availability
          ?.filter((i) => i.status)
          ?.map((j) => {
            delete j?._id;
            return j;
          });
      }

      /*************************For Client*****************/
      if (role == constant.ROLE.CLIENT) {
        delete body.availability;
      }

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => updateUser(details?._id, body),
    onSuccess: async (resp) => {
      let data = {
        ...details,
        email: values.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        profilePhoto: values?.profilePhoto,
      };
      if (role == constant.ROLE.EMPLOYEE) {
        data.employeeDetails = {
          ...details?.employeeDetails,
          availability: values?.availability?.filter((i) => i.status),
        };
      }
      dispatch(login(data));
      toastAlert("success", resp?.data?.message);
      navigate(-1);
    },
  });

  useEffect(() => {
    if (details) {
      setValues({
        firstName: details?.firstName,
        lastName: details?.lastName,
        email:details?.email,
        profilePhoto: details?.profilePhoto,
        availability: values?.availability?.map((item) => {
          const dayData = details?.employeeDetails?.availability?.find(
            (i) => i.day === item.day
          );
          return dayData ? { ...item, ...dayData } : item;
        }),
        authorizedEmployees: details?.authorizedEmployees,
      });
    }
  }, [details]);

  const imageMutation = useMutation({
    mutationFn: (body) => uploadFile(body),
    onSuccess: (resp) => {
      setFieldValue("profilePhoto", resp?.data?.data?.fileUrl);
    },
  });

  return (
    <div className={"main-content"}>
      <div className="userData">
        <Row>
          <Col md={4}>
            <div className="userLeft">
              <div className="userImg position-relative">
                <img
                  src={
                    values?.profilePhoto ? values?.profilePhoto : images.profile
                  }
                  alt="profile"
                  width={250}
                  className="profileUploadImg"
                />

                <label htmlFor="profileImage" className="cameraUpload">
                  <FaCamera size={20} />
                </label>

                <input
                  type="file"
                  id="profileImage"
                  className="d-none"
                  accept="image/*"
                  onClick={(e) => (e.target.value = null)}
                  onChange={(e) => {
                    const file = e.target.files[0];

                    if (
                      file &&
                      !constant.SUPPORTED_FORMATS.includes(file.type)
                    ) {
                      toastAlert(
                        "error",
                        "Unsupported file format. Please choose png, jpg, or jpeg."
                      );
                      return;
                    }
                    let formData = new FormData();
                    formData.append("file", file);
                    imageMutation.mutate(formData);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={8}>
            <Row className="mt-4">
              <Col md={8} className="mb-2">
                <label className="heading16">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={values?.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                <small className="text-danger requiredTxt">
                  {touched?.firstName && errors?.firstName}
                </small>
              </Col>
              <Col md={8} className="mb-2">
                <label className="heading16">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={values?.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                <small className="text-danger requiredTxt">
                  {touched?.lastName && errors?.lastName}
                </small>
              </Col>
              <Col md={8} className="mb-2">
                <label className="heading16 mb-0">Email</label>
                <input
                  type="email"
                  name="email"
                  value={values?.email}
                  // disabled={role == constant.ROLE.ADMIN}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {role == constant.ROLE.EMPLOYEE && (
          <div className="userWork ps-4">
            <div className="workingDays">
              <h5 className="prefferedDays">Preffered Working Days</h5>
            </div>
            <Row>
              {values?.availability?.map((item, index) => (
                <Col md={12} className="mb-3" key={index}>
                  <Row>
                    <Col md={2}>
                      <div className="form-group">
                        <label className="labelTxt">
                          {getDaysString([item?.day])}
                        </label>
                        <div className="toggle-container py-2">
                          <div
                            role="button"
                            className={`toggle-switch ${
                              item?.status ? "toggled" : ""
                            }`}
                            onClick={() =>
                              setFieldValue(
                                `availability[${index}].status`,
                                !item?.status
                              )
                            }
                          >
                            <div
                              className={`slider ${
                                item?.status ? "toggled" : ""
                              }`}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="form-group">
                        <label className="labelTxt">
                          Start Time <span className="text-danger">*</span>
                        </label>
                        <input
                          type="time"
                          name={`availability[${index}].startTime`}
                          className="timePicker inputBox"
                          value={item?.startTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!item?.status} // Disable input if status is false
                        />
                        <small className="text-danger requiredTxt">
                          {touched?.availability?.[index]?.startTime &&
                            errors?.availability?.[index]?.startTime}
                        </small>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="form-group">
                        <label className="labelTxt">
                          End Time <span className="text-danger">*</span>
                        </label>
                        <input
                          type="time"
                          name={`availability[${index}].endTime`}
                          className="timePicker inputBox"
                          value={item?.endTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!item?.status} // Disable input if status is false
                        />
                        <small className="text-danger requiredTxt">
                          {touched?.availability?.[index]?.endTime &&
                            errors?.availability?.[index]?.endTime}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
            {!Array.isArray(errors?.availability) && (
              <small className="text-danger requiredTxt">
                {touched?.availability && errors?.availability}
              </small>
            )}
          </div>
        )}

        <div className="staffBtn mt-4">
          <button className="addBtn me-3" type="submit" onClick={handleSubmit}>
            Save
          </button>
          <button
            className="cancleBtn"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
