import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App.jsx";
import { login } from "./redux/features/authSlice.js";
import { persistor, store } from "./redux/store.js";
import { toastAlert } from "./utils/SweetAlert.js";
import { constant } from "./utils/constants.js";

const handleError = (error) => {
  if (error.response?.status === constant.STATUS_CODE.UNAUTHORIZED) {
    toastAlert("error", error.response?.data?.message);
    store.dispatch(login({}));
    queryClient.clear();
    return true;
  }
  toastAlert("error", error.response?.data?.message);
  queryClient.clear();
  return true;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
});

createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <StrictMode>
            <App />
          </StrictMode>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);
