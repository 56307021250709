import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";

const PayoutModal = ({ show, onClose, payoutData, onSave }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) return null;

  const parseTimeStringToDate = (timeString) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(":");
    return moment().set({ hour: +hours, minute: +minutes }).toDate();
  };

  const validationSchema = Yup.object().shape({
    checkIn: Yup.date()
      .required("Check In time is required")
      .typeError("Invalid Check-In time"),
    checkOut: Yup.date()
      .required("Check Out time is required")
      .typeError("Invalid Check-Out time")
      .test(
        "is-greater",
        "Check-Out time must be greater than Check-In time",
        function (value) {
          const { checkIn } = this.parent;
          return moment(value).isAfter(moment(checkIn));
        }
      ),
  });

  const parseTimeInput = (input, shiftDate = new Date()) => {
    let hours,
      minutes = 0;
    const isPM = /pm$/i.test(input);
    const isAM = /am$/i.test(input);

    const timeString = input.replace(/(am|pm)/i, "").trim();
    if (timeString.length === 1) {
      hours = parseInt(timeString[0], 10);
    } else if (timeString.length === 2) {
      hours = parseInt(timeString, 10);
    } else if (timeString.length === 3) {
      hours = parseInt(timeString[0], 10);
      minutes = parseInt(timeString.slice(1), 10);
    } else if (timeString.length === 4) {
      hours = parseInt(timeString.slice(0, 2), 10);
      minutes = parseInt(timeString.slice(2), 10);
    } else {
      return null;
    }

    if (isPM && hours < 12) hours += 12;
    if (isAM && hours === 12) hours = 0;

    const resultDate = new Date(shiftDate || Date.now());
    resultDate.setHours(hours, minutes, 0, 0);

    return resultDate;
  };

  return (
    <div className="payout-modal">
      <div className="modal-overlay">
        <div className="modal-content" ref={modalRef}>
          <h2>Edit Payout</h2>
          <Formik
            initialValues={{
              checkIn: parseTimeStringToDate(payoutData?.checkIn),
              checkOut: parseTimeStringToDate(payoutData?.checkOut),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formattedValues = {
                checkIn: values.checkIn
                  ? moment(values.checkIn).format("HH:mm")
                  : null,
                checkOut: values.checkOut
                  ? moment(values.checkOut).format("HH:mm")
                  : null,
              };
              onSave(formattedValues);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-In
                        <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={values.checkIn}
                        onChange={(date) => setFieldValue("checkIn", date)}
                        showTimeSelect
                        showTimeSelectOnly
                        onChangeRaw={(e) => {
                          const input = e.target.value.trim();
                          if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                            const parsedDate = parseTimeInput(
                              input,
                              values?.date
                            );
                            if (parsedDate) {
                              setFieldValue("checkIn", parsedDate);
                            }
                          }
                        }}
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                      />
                      <ErrorMessage
                        style={{ fontSize: "14px" }}
                        name="checkIn"
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-Out
                        <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={values.checkOut}
                        onChange={(date) => setFieldValue("checkOut", date)}
                        showTimeSelect
                        showTimeSelectOnly
                        onChangeRaw={(e) => {
                          const input = e.target.value.trim();
                          if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                            const parsedDate = parseTimeInput(
                              input,
                              values?.date
                            );
                            if (parsedDate) {
                              setFieldValue("checkOut", parsedDate);
                            }
                          }
                        }}
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                      />
                      <ErrorMessage
                        style={{ fontSize: "14px" }}
                        name="checkOut"
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="staffBtn mt-4 text-center">
                  <button
                    className="greenBtn me-3"
                    type="button"
                    onClick={onClose}
                  >
                    Close
                  </button>
                  <button className="cancleBtn" type="submit">
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PayoutModal;
