import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { constant } from "../utils/constants";
import { toastAlert } from "../utils/SweetAlert";
import { AsyncPaginate } from "react-select-async-paginate";
import { getUsers } from "../services/services";

export default function FilterModal(props) {
  const { show, setShow, data, setData, refetch, fromAdmin } = props;
  const [filterData, setFilterData] = useState({
    start: "",
    end: "",
  });

  const handleClose = () => {
    setShow(false);
    if (refetch) refetch();
  };

  const handleDateChange = (date, key) => {
    if (!date) return;
    let isValid = true;
    let toastMessage = "Invalid date selected";
    const momentInstance = moment(date).startOf("day"); // Ensure the date is at the start of the day (local time)

    if (key === "end" && filterData.start) {
      const start = moment(filterData.start).startOf("day");
      if (momentInstance.isBefore(start)) {
        isValid = false;
        toastMessage = "End date should not be before the start date";
      }
    } else if (key === "start" && filterData.end) {
      const end = moment(filterData.end).startOf("day");
      if (momentInstance.isAfter(end)) {
        isValid = false;
        toastMessage = "Start date should not be after the end date";
      }
    }

    if (!isValid) {
      toastAlert("warning", toastMessage);
      return;
    }

    // Store the date in a timezone-agnostic format
    setFilterData({
      ...filterData,
      [key]: momentInstance.format(constant.DATE_ONLY_FORMAT),
    });
  };
  const handleFilterChange = (key, value) => {
    setFilterData((prev) => ({ ...prev, [key]: value }));
  };
  const applyFilter = () => {
    setData({ ...filterData });
    handleClose();
  };
  const resetFilters = () => {
    setData({});
    handleClose();
  };

  useEffect(() => {
    if (data) setFilterData(data);
  }, [data]);

  const getClients = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.CLIENT,
      sortedBy: constant.SORTED_BY.FIRST_NAME,
      sortBy: constant.SORT_BY.ASC,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const getEmployee = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.EMPLOYEE,
      sortedBy: constant.SORTED_BY.FIRST_NAME,
      sortBy: constant.SORT_BY.ASC,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Modal show={!!show} onHide={handleClose} centered className="addModal">
      <Modal.Header closeButton>
        <Modal.Title>Filter Criterias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {fromAdmin ? (
            <React.Fragment>
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label className="labelTxt">
                    Client <span className="text-danger">*</span>
                  </label>
                  <AsyncPaginate
                    additional={{ page: 1 }}
                    styles={constant.REACT_SELECT_CUSTOM_STYLE}
                    debounceTimeout={500}
                    value={filterData.clientId}
                    loadOptions={getClients}
                    onChange={(e) => handleFilterChange("clientId", e)}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label className="labelTxt">Employee</label>
                  <AsyncPaginate
                    additional={{ page: 1 }}
                    isClearable={true}
                    styles={constant.REACT_SELECT_CUSTOM_STYLE}
                    value={filterData.employeeId}
                    debounceTimeout={500}
                    loadOptions={getEmployee}
                    onChange={(e) => handleFilterChange("employeeId", e)}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h6 className="heading18">Date range</h6>
              <div className="col-md-6">
                <DatePicker
                  selected={
                    filterData?.start
                      ? moment(
                          filterData.start,
                          constant.DATE_ONLY_FORMAT
                        ).toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "start")}
                  className="inputBox"
                />
              </div>
              <div className="col-md-6">
                <DatePicker
                  selected={
                    filterData?.end
                      ? moment(
                          filterData.end,
                          constant.DATE_ONLY_FORMAT
                        ).toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "end")}
                  className="inputBox"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center gap-3 ">
          <button
            className="greenBtn ms-0"
            type="button"
            onClick={resetFilters}
          >
            {" "}
            Reset Filters{" "}
          </button>
          <button className="cancleBtn" type="button" onClick={handleClose}>
            {" "}
            Close{" "}
          </button>
          <button className="greenBtn" type="button" onClick={applyFilter}>
            {" "}
            Apply Filter{" "}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
