import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import NavigateBack from "../../common/NavigateBack";
import { addUser, getUserDetails, updateUser } from "../../services/services";
import { constant } from "../../utils/constants";
import { getDaysString, noSpecialChars } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const AddEmployee = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setValues,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      hourlyRate: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      role: constant.ROLE.EMPLOYEE,
      password: "",
      availability: [
        {
          day: 0,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 1,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 2,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 3,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 4,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 5,
          status: false,
          startTime: "",
          endTime: "",
        },
        {
          day: 6,
          status: false,
          startTime: "",
          endTime: "",
        },
      ],
    },
    validationSchema: yup.object().shape({
      firstName: yup
        .string()
        .required()
        .label("First name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "First name should contain letters only"
        ),
      lastName: yup
        .string()
        .required()
        .label("Last name")
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Last name should contain letters only"
        ),
      email: yup.string().required().email().label("Email").trim(),
      phoneNumber: yup
        .string()
        .min(5)
        .label("Phone Number")
        .test("phone-validate", "Invalid phone number", function (value) {
          if (value?.length > 6) {
            return isValidPhoneNumber(String(value));
          } else {
            return true;
          }
        }),
      hourlyRate: yup
        .number()
        .required()
        .positive()
        .label("Hourly Rate")
        .typeError("Invalid number input"),
      availability: yup
        .array()
        .of(
          yup.object().shape({
            startTime: yup.string().when("status", {
              is: (value) => value,
              then: () => yup.string().required("Start time is required"),
              otherwise: () => yup.string().notRequired(),
            }),
            endTime: yup.string().when("status", {
              is: (value) => value,
              then: () =>
                yup
                  .string()
                  .required("End time is required")
                  .matches(
                    /^([01]\d|2[0-3]):([0-5]\d)$/,
                    "End time must be in HH:mm format"
                  )
                  .test(
                    "is-greater-than-startTime",
                    "End time must be later than start time",
                    function (value) {
                      const { startTime } = this.parent;
                      if (!startTime || !value) return true; // Skip validation if either time is missing
                      const [startHour, startMinute] = startTime
                        .split(":")
                        .map(Number);
                      const [endHour, endMinute] = value.split(":").map(Number);

                      const startTotalMinutes = startHour * 60 + startMinute;
                      const endTotalMinutes = endHour * 60 + endMinute;
                      return endTotalMinutes > startTotalMinutes;
                    }
                  ),
              otherwise: () => yup.string().notRequired(),
            }),
          })
        )
        .test(
          "at-least-one-status-true",
          "At least one day must be active",
          (availability) => {
            if (!availability || !Array.isArray(availability)) return false;
            return availability.some((day) => day.status === true);
          }
        ),
      address: yup.string().required().label("Address").trim().min(2),
      city: yup.string().required().label("City").trim().min(2),
      state: yup.string().required().label("State").trim().min(2),
      country: yup.string().label("Country").trim().min(2),
      zipCode: yup
        .string()
        .required()
        .label("Zip Code")
        .trim()
        .min(2)
        .matches(/^[a-zA-Z0-9]*$/, "Invalid zip code"),
    }),
    onSubmit: (values) => {
      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        address: values?.address,
        city: values?.city,
        state: values?.state,
        country: values?.country,
        zipCode: values?.zipCode,
        hourlyRate: +values?.hourlyRate,
        availability: values?.availability
          ?.filter((i) => i.status)
          ?.map((j) => {
            delete j?._id;
            return j;
          }),
        role: constant.ROLE.EMPLOYEE,
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => (id ? updateUser(id, body) : addUser(body)),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../employee");
    },
  });

  useQuery({
    queryKey: ["user-details", id],
    queryFn: async () => {
      const resp = !!id && (await getUserDetails(id));
      let data = resp?.data?.data;

      if (data) {
        setValues({
          ...values,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          address: data?.userAddress?.address,
          city: data?.userAddress?.city,
          state: data?.userAddress?.state,
          country: data?.userAddress?.country,
          zipCode: data?.userAddress?.zipCode,
          hourlyRate: data?.employeeDetails?.hourlyRate,
          role: constant.ROLE.EMPLOYEE,
          availability: values?.availability?.map((item) => {
            const dayData = data?.employeeDetails?.availability?.find(
              (i) => i.day === item.day
            );

            return dayData ? { ...item, ...dayData } : item;
          }),
        });
      }
      return true;
    },
  });

  const handleAvailableToggle = (index, status) => {
    const isChecked = !status
    setFieldValue(`availability[${index}].status`, isChecked)
    if (isChecked) {
      setFieldValue(`availability[${index}].startTime`, constant.DEFAULT_AVAILABILITY.START)
      setFieldValue(`availability[${index}].endTime`, constant.DEFAULT_AVAILABILITY.END)
    } else {
      setFieldValue(`availability[${index}].startTime`, "")
      setFieldValue(`availability[${index}].endTime`, "")
    }
  }

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>{!!id ? "Edit Employee" : "Add Employee"}</NavigateBack>
          <Card border="light" bg="light" className="p-4">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.firstName && errors?.firstName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Last Name</label>
                      <input
                        className="inputBox"
                        type="text"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.lastName && errors?.lastName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt" htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.email && errors?.email}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Contact No <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        defaultCountry={constant.DEFAULT_COUNTRY}
                        value={values?.phoneNumber}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                        onBlur={() => setFieldTouched("phoneNumber", true)}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.phoneNumber && errors?.phoneNumber}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Hourly Rate($)<span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Hourly Rate"
                        name="hourlyRate"
                        value={values?.hourlyRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.hourlyRate && errors?.hourlyRate}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.address && errors?.address}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        onKeyDown={noSpecialChars}
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.city && errors?.city}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        onKeyDown={noSpecialChars}
                        placeholder="Enter State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.state && errors?.state}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Country</label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        onKeyDown={noSpecialChars}
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.country && errors?.country}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Zipcode <span className="text-danger">*</span>
                      </label>
                      <input
                        className="inputBox"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        placeholder="Enter Zipcode"
                        name="zipCode"
                        value={values?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={6}
                      />
                      <small className="text-danger requiredTxt">
                        {touched?.zipCode && errors?.zipCode}
                      </small>
                    </div>
                  </div>
                  <div className="userWork mb-3">
                    <div className="workingDays">
                      <label className="labelTxt">
                        Preffered Working Days{" "}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <Row>
                    {values?.availability?.map((item, index) => (
                      <Col md={12} className="mb-3" key={index}>
                        <Row>
                          <Col md={2}>
                            <div className="form-group">
                              <label className="labelTxt">
                                {getDaysString([item?.day])}
                              </label>
                              <div className="toggle-container py-2">
                                <div
                                  role="button"
                                  className={`toggle-switch ${
                                    item?.status ? "toggled" : ""
                                  }`}
                                  onClick={() =>
                                    handleAvailableToggle(index, item.status)
                                  }
                                >
                                  <div
                                    className={`slider ${item?.status ? "toggled" : ""
                                      }`}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="form-group">
                              <label className="labelTxt">
                                Start Time{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                name={`availability[${index}].startTime`}
                                className="timePicker inputBox"
                                value={item?.startTime}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!item?.status} // Disable input if status is false
                              />
                              <small className="text-danger requiredTxt">
                                {touched?.availability?.[index]?.startTime &&
                                  errors?.availability?.[index]?.startTime}
                              </small>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="form-group">
                              <label className="labelTxt">
                                End Time <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                name={`availability[${index}].endTime`}
                                className="timePicker inputBox"
                                value={item?.endTime}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!item?.status} // Disable input if status is false
                              />
                              <small className="text-danger requiredTxt">
                                {touched?.availability?.[index]?.endTime &&+
                                  errors?.availability?.[index]?.endTime}
                              </small>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                  {!Array.isArray(errors?.availability) && (
                    <small className="text-danger requiredTxt">
                      {touched?.availability && errors?.availability}
                    </small>
                  )}
                </div>
              </div>

              <div className="staffBtn mt-4">
                <Link className="cancleBtn me-3" role="button" to={-1}>
                  Cancel
                </Link>
                <button className="addBtn " type="submit">
                  {!!id ? "Update" : "Add"}
                </button>
              </div>
            </Form>
          </Card>
        </div>
      </section>
    </>
  );
};

export default AddEmployee;
