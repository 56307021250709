import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import * as yup from "yup";
import useRole from "../../hooks/useRole";
import { resetPassword } from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";

const ChangePassword = () => {
  const role = useRole();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    },
    validationSchema: yup.object().shape({
      oldPassword: yup.string().required().trim().label("Old Password"),
      newPassword: yup
        .string()
        .required()
        .trim()
        .label("New Password")
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
          "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character."
        ),
      confirmPassword: yup
        .string()
        .required()
        .label("Confirm Password")
        .trim()
        .oneOf(
          [yup.ref("newPassword"), null],
          "New password and confirm password must match"
        ),
    }),
    onSubmit: () => {
      let body = {
        old_password: values?.oldPassword,
        new_password: values?.newPassword,
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => resetPassword(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      resetForm();
    },
  });

  return (
    <div
      className={
          "main-content"
      }
    >
      <div className="clientInputBox">
        <div className="row mt-5 mx-auto w-50">
          <div className="col-md-12 mb-3">
            <div className="form-group position-relative">
              <label className="labelTxt">Old Password</label>
              <input
                className="inputBox"
                type={values?.showOldPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                value={values?.oldPassword}
                name="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="eye-icon"
                onClick={() =>
                  setFieldValue("showOldPassword", !values?.showOldPassword)
                }
              >
                {values?.showOldPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </div>
            </div>
            <small className="text-danger requiredTxt">
              {touched?.oldPassword && errors?.oldPassword}
            </small>
          </div>
          <div className="col-md-12 mb-3">
            <div className="form-group position-relative">
              <label className="labelTxt">New Password</label>
              <input
                className="inputBox"
                type={values?.showNewPassword ? "text" : "password"}
                value={values?.newPassword}
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter New Password"
              />
              <div
                className="eye-icon"
                onClick={() =>
                  setFieldValue("showNewPassword", !values?.showNewPassword)
                }
              >
                {values?.showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </div>
            </div>
            <small className="text-danger requiredTxt">
              {touched?.newPassword && errors?.newPassword}
            </small>
          </div>
          <div className="col-md-12">
            <div className="form-group position-relative">
              <label className="labelTxt">Confirm Password</label>
              <input
                type={values?.showConfirmPassword ? "text" : "password"}
                className="inputBox"
                placeholder="Enter Confirm Password"
                value={values?.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="eye-icon"
                onClick={() =>
                  setFieldValue(
                    "showConfirmPassword",
                    !values?.showConfirmPassword
                  )
                }
              >
                {values?.showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </div>
            </div>
            <small className="text-danger requiredTxt">
              {touched?.confirmPassword && errors?.confirmPassword}
            </small>
          </div>
          <div>
            <div className="staffBtn mt-4">
              <button
                className="cancleBtn me-3"
                type="button"
                onClick={resetForm}
              >
                Cancel
              </button>
              <button className="addBtn" type="button" onClick={handleSubmit}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
