import moment from "moment";
import { toastAlert } from "./SweetAlert";
import { constant } from "./constants";

export function setTimeFromString(date, timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

/**
 *
 * @param {*} checkinTime
 * @param {*} checkoutTime
 * @param {*} hourlyRate
 * @returns
 */

export const calculatePayoutAmount = (
  checkinTime,
  checkoutTime,
  hourlyRate
) => {
  const [checkinHours, checkinMinutes] = checkinTime.split(":").map(Number);
  const [checkoutHours, checkoutMinutes] = checkoutTime.split(":").map(Number);

  const today = new Date();
  const checkin = new Date(today.setHours(checkinHours, checkinMinutes, 0, 0));
  let checkout = new Date(today.setHours(checkoutHours, checkoutMinutes, 0, 0));

  if (checkout < checkin) {
    checkout.setDate(checkout.getDate() + 1);
  }

  const timeDifference = checkout - checkin;

  if (timeDifference <= 0) {
    toastAlert("error", "Invalid check-in or check-out times");
    return 0;
  }

  const hoursWorked = timeDifference / (1000 * 60 * 60);
  const payoutAmount = hoursWorked * hourlyRate;

  return parseFloat(payoutAmount.toFixed(2));
};

export const getDaysString = (dayNumbers = []) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return dayNumbers?.map((dayNumber) => daysOfWeek[dayNumber])?.join(", ");
};

export const getRecurringSlots = async (
  startDate,
  numberOfDays,
  reccuredOn = []
) => {
  const startDateObject = new Date(startDate); // Date to start from
  const recurringSlots = [];

  for (let day = 0; day <= numberOfDays; day++) {
    const nextDate = new Date(startDateObject);
    nextDate.setDate(nextDate.getDate() + day);
    const dayOfWeek = nextDate.getDay();
    if (reccuredOn.includes(`${dayOfWeek}`))
      recurringSlots.push({ date: nextDate, weekDay: dayOfWeek });
  }

  return recurringSlots;
};

export const getMinuteDifference = (start, end) => {
  const startTime = moment(start, "HH:mm");
  const endTime = moment(end, "HH:mm");
  return endTime.diff(startTime, "minutes") || 0;
};

export const checkSingleAvailabilty = (employee, eventTiming) => {
  try {
    let isAvailable = true;
    const momentEventStart = moment(eventTiming.startTime); // event Start time
    const momentEventTill = moment(eventTiming.endTime);
    const weekDay = momentEventStart.day();

    if (!employee?.workingDays?.includes(String(weekDay))) return false;

    const employeeStartTime = moment(
      `${momentEventStart.format("YYYY-MM-DD")} ${employee.workingHours.startTime
      }`,
      "YYYY-MM-DD hh:mm:ss"
    ); // employee starting hours
    const employeeEndTime = moment(
      `${momentEventStart.format("YYYY-MM-DD")} ${employee.workingHours.endTime
      }`,
      "YYYY-MM-DD hh:mm:ss"
    ); // employee ending hours

    if (momentEventStart.isBefore(employeeStartTime)) return false; // check if the event is stating before the emplayee availablity
    if (momentEventTill.isAfter(employeeEndTime)) return false;

    return isAvailable;
  } catch (error) {
    return false;
  }
};

export const generatePassword = (formData) => {
  try {
    const { firstName, lastName } = formData;
    if (
      !firstName ||
      firstName?.trim() == "" ||
      !lastName ||
      lastName?.trim() == ""
    )
      return null;
    let password = "";
    password = `${firstName?.charAt(0)?.toUpperCase()}${lastName
      ?.trim()
      ?.toLowerCase()}${constant.PASSWORD_SUFFIX}`;
    return password;
  } catch (error) {
    console.log("Error@generatePassword", error);
  }
};

export const getTableIndex = (page, index) => {
  return (page - 1) * constant.PAGE_LIMIT + index + 1;
};

const allowedKeys = [
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Space",
];

export const numOnly = (e) => {
  if (
    !/[0-9]/.test(e.key) && // Prevent non-numeric input
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const noSpecialChars = (e) => {
  if (
    !/[a-zA-Z0-9]/.test(e.key) && // Prevent non-alphanumeric input
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const decimalOnly = (e) => {
  const isDecimalPointAllowed =
    e.key === "." && e.target.value.includes(".") === false;

  if (
    !/[0-9]/.test(e.key) &&
    !isDecimalPointAllowed &&
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const convertToHours = (minutes) => {
  if (!Number.isFinite(minutes) || minutes < 0) {
    throw new Error("Invalid input. Minutes must be a non-negative number.");
  }

  const hours = Math.floor(minutes / 60); // Get the whole hours
  const remainingMinutes = minutes % 60; // Get the remaining minutes

  return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
};


export const base64toImageFile = (base64String, filename) => {
  try {
    const [metadata, base64Data] = base64String.split(',')
    const mimeType = metadata.match(/:(.*?);/)[1];

    const binaryData = atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryData.length)

    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }
    
    return new Blob([arrayBuffer], { type: mimeType })

  } catch (error) {
    console.log("base64toImage>>error", error)
  }
}

export const downloadFile = (blob, fileName) => {
  const blobUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName ;
  document.body.appendChild(link);
  link.click();
  
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}