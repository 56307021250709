import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState,useRef } from "react";
import { AiOutlineFileSync } from "react-icons/ai";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import { getEvents, syncEvents } from "../../services/services";
import { constant } from "../../utils/constants";
import { getTableIndex } from "../../utils/function";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import { FaCheck, FaTrash, FaXmark } from "react-icons/fa6";
import { deleteEvent, updateEventStatus } from "../../services/services";

const Shifts = () => {
  const [search, setSearch] = useState();
    const [isToggled, setIsToggled] = useState(false);
  const [page, setPage] = useState(constant.PAGE_NO_ONE);
  const [selectedEvents, setSelectedEvents] = useState([]); // New state to manage selected rows
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const { data, refetch } = useQuery({
    queryKey: ["shift-list", page,isToggled],
    queryFn: async () => {
      let params = {
        page: page,
        limit: constant.PAGE_LIMIT,
        sortedBy: constant.SORTED_BY.START_TIME,
        sortBy: constant.SORT_BY.ASC,
      };
      if(isToggled){
        params.previousEvents=true
      }else(
        params.futureEvents=true
      )
      if (search) params.search = search;
      const resp = await getEvents(params);
      return resp?.data?.data || {};
    },
  });

  const handleEventSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events to your Google calendar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncEventsMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncEventsMutation = useMutation({
    mutationFn: () => syncEvents(),
    onSuccess: async (resp) => {
      if (resp.data.statusCode == constant.STATUS_CODE.SUCCESS) {
        toastAlert("success", resp.data?.message);
      }
      refetch();
    },
  });

  const eventMutation = useMutation({
    mutationFn: ({ id, status }) => updateEventStatus(id, status),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteMutation.mutate(id);
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteEvent(id),
    onSuccess: (resp) => {
      Swal.fire({
        title: "Deleted!",
        text: resp?.data?.message,
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  // Handler to toggle selection of individual events
  const toggleEventSelection = (eventId) => {
    setSelectedEvents((prev) =>
      prev.includes(eventId)
        ? prev.filter((id) => id !== eventId)
        : [...prev, eventId]
    );
  };

  // Handler to toggle selection of all events
  const toggleSelectAll = () => {
    // Filter out events that are "confirmed" or "accepted"
    const selectableEvents = data?.events?.filter(
      (event) => event.status !== "confirmed" && event.status !== "accepted"&&moment(event?.startTime).isAfter()&& event.status!=="rejected"&&event.status!=="cancelled"
    );

    // Check if all selectable events are already selected
    if (selectedEvents.length === selectableEvents.length) {
      setSelectedEvents([]); // Deselect all
    } else {
      setSelectedEvents(selectableEvents.map((event) => event._id)); // Select all
    }
  };

  // Handler to approve all selected events
  const approveSelectedEvents = () => {
    selectedEvents.forEach((id) => {
      eventMutation.mutate({ id, status: constant.EVENT_STATUS.ACCEPTED });
    });
    setSelectedEvents([]); // Clear selection after approving
  };

  const denySelectedEvents = () => {
    selectedEvents.forEach((id) => {
      eventMutation.mutate({ id, status: constant.EVENT_STATUS.REJECTED });
    });
    setSelectedEvents([]); // Clear selection after approving
  };
  const isEventInNext24HoursOrNextDay = (startTime) => {
    const currentTime = moment();
    const endOfToday = moment().endOf("day");
    const next24Hours = moment().add(24, "hours");
    const eventStartTime = moment(startTime);
  
    // Check if the event is on the current day
    const isOnCurrentDay = eventStartTime.isSame(currentTime, "day");
  
    // Check if the event is within the next 24 hours
    const isInNext24Hours = eventStartTime.isBetween(currentTime, next24Hours);
  
    // Check if the event is on the next calendar day
    const isOnNextDay = eventStartTime.isSame(currentTime.add(1, "days"), "day");
  
    return isOnCurrentDay || isInNext24Hours || isOnNextDay;
  };
  
const clickTimer = useRef(null);
   const handleEventClick1 = () => {
      
      clearTimeout(clickTimer.current);
      clickTimer.current = setTimeout(() => {
        Swal.fire({
          icon: "warning",
          title: "Actions",
          text: "Please contact manager to change schedule!",
          showCloseButton: false, // Hide the close (X) button
          confirmButtonText: "OK", // Set text for the confirm button
          allowOutsideClick: false, // Prevent closing modal by clicking outside
        }).then((result) => {
          if (result.isConfirmed) {
            // The modal will close automatically when the user clicks "OK".
          }
        });
      }, 300);
    };

  return (
    <section className="main-content">
      <div className="commonSearchBar mt-3">
        <h4 className="heading20">Upcoming Schedules</h4>
        <div className="endContent">
        <div className="toggle-container">
            <div
              className={`toggle-switch ${isToggled ? "toggled" : ""}`}
              onClick={handleToggle}
            >
              <div className={`slider ${isToggled ? "toggled" : ""}`}></div>
            </div>
            <label className="label-text">Show Old Assignments</label>
          </div>
          <button
            className="greenBtn"
            type="button"
            title="Sync with Google calendar"
            onClick={handleEventSync}
          >
            <AiOutlineFileSync size={30} />
          </button>
          <div className="clientSearch">
            <img
              src={images.Search}
              alt="searchImg"
              className="clientSearchIcon"
            />
            <input
              type="text"
              placeholder="Type and press enter to search."
              className="clientSearchInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key === "Enter" && search.trim() !== "" && refetch()
              }
              onKeyUp={(e) =>
                e.target.value === "" && e.key === "Backspace" && refetch()
              }
            />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="commonTable">
                <thead className="tableHead">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={toggleSelectAll}
                        checked={
                          data?.events?.length > 0 &&
                          selectedEvents.length === data?.events?.length
                        }
                      />
                    </th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Client Name</th>
                    <th>Note</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Accept/Deny</th>
                    <th>Status</th>
                    <th>Confirm</th>  
                  </tr>
                </thead>
                <tbody>
                  {data && data?.events && data?.events?.length > 0 ? (
                    data?.events?.map((event, index) => {
                      const clientDetails = event?.clientDetails || null;
                      return (
                        <tr key={`event_${index}`}>
                          <td>
                            {event?.status !== "confirmed" &&
                            event?.status!=="cancelled"&&
                              event?.status !== "accepted"&&event?.status !== "rejected" && moment(event?.startTime).isAfter()&& (
                                <input
                                  type="checkbox"
                                  checked={selectedEvents.includes(event._id)}
                                  onChange={() =>
                                    toggleEventSelection(event._id)
                                  }
                                />
                              )}
                          </td>
                          <td>{moment(event?.startTime).format("ll")}</td>
                          <td>
                            {moment(event?.startTime).format("LT")} -{" "}
                            {moment(event?.endTime).format("LT")}
                          </td>
                          <td className="text-capitalize">{`${clientDetails?.firstName} ${clientDetails?.lastName}`}</td>
                          <td>{event?.note || "--"}</td>
                          <td>{`${clientDetails?.userAddress?.address} ${clientDetails?.userAddress?.city} ${clientDetails?.userAddress?.state} ${clientDetails?.userAddress?.country||''} (${clientDetails?.userAddress?.zipCode})`}</td>
                          <td>{clientDetails?.phoneNumber}</td>
                          <td>{clientDetails?.email}</td>
                          <td>
                            {moment(event?.startTime).isAfter() && (
                              <>
                                <FaCheck
                                  size={20}
                                  role="button"
                                  className="me-2"
                                  onClick={() => {
                                    if (
                                      event.status !==
                                      constant.EVENT_STATUS.ACCEPTED&&(event.status !== "confirmed"&&event.status!=="accepted"&&event.status!=="rejected")
                                    )
                                   {   eventMutation.mutate({
                                        id: event?._id,
                                        status: constant.EVENT_STATUS.ACCEPTED,
                                      });}
                                      else{
                                        handleEventClick1()
                                      }
                                  }}
                                />
                                <FaXmark
                                  size={20}
                                  disabled={event?.status==="confirmed"}
                                  role="button"
                                  onClick={() => {
                                    if (
                                      event.status !==
                                      constant.EVENT_STATUS.REJECTED && (event.status !== "confirmed"&&event.status!=="rejected"&&event.status!=="accepted")
                                    ){
                                      eventMutation.mutate({
                                        id: event?._id,
                                        status: constant.EVENT_STATUS.REJECTED,
                                      });
                                    }else{
                                      handleEventClick1()
                                    }
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td>
                            {event?.status === "accepted" ? (
                              <span className="text-success">Accepted</span>
                            ) : event?.status === "rejected" ? (
                              <span className="text-danger">Denied</span>
                            ) : event?.status === "confirmed" ? (
                              <span className="text-primary">Confirmed</span>
                            ) : event?.status==="cancelled"?(
                              <span className="text-primary">Cancelled</span>
                            ): <span className="text-warning">Pending</span>}
                          </td>
                         
                          <td>
                            {(event?.status === "accepted" ||
                              event?.status === "confirmed") &&
                              isEventInNext24HoursOrNextDay(
                                event?.startTime
                              ) && (
                                <button
                                  className="confirmBtn"
                                  disabled={event?.status === "confirmed"}
                                  onClick={() =>
                                    eventMutation.mutate({
                                      id: event?._id,
                                      status: constant.EVENT_STATUS.CONFIRMED,
                                    })
                                  }
                                >
                                  {event?.status !== "confirmed"
                                    ? "Confirm"
                                    : "Confirmed"}
                                </button>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <button
                className="mt-4 approve-btn"
                onClick={approveSelectedEvents}
                disabled={selectedEvents.length === 0}
              >
                Accept Selected
              </button>
              <button
                className="mt-4 ms-3 approve-btn"
                onClick={denySelectedEvents}
                disabled={selectedEvents.length === 0}
              >
                Deny Selected
              </button>
              <Pagination
                currentPage={page}
                totalPages={data?.totalPages}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shifts;
