import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState, useRef } from "react";
import { Card, Form } from "react-bootstrap";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as images from "../../assets/image";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import NavigateBack from "../../common/NavigateBack";
import {
  addPayout,
  getEvents,
  getUserByEvents,
  uploadSignFile,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { base64toImageFile, setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";
import SignatureCanvas from "react-signature-canvas";
import useDetails from "../../hooks/useDetails";

const AddPayOut = () => {
  const dateRef = useRef();
  const signRef = useRef(null);
  const [signed, setSigned] = useState(null);

  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      clientId: "",
      employeeId: "",
      date: new Date(),
      checkIn: "",
      checkOut: "",
      note: "",
    },
    validationSchema: yup.object().shape({
      date: yup.date().required().label("Shift Date"),
      clientId: yup.object().shape({
        value: yup.string().required().label("Client"),
      }),

      checkIn: yup.string().required().label("Check In"),
      checkOut: yup
        .string()
        .required()
        .label("Checkout time")
        .test(
          "is-greater-than-startTime",
          "check out time should be later than start time",
          function (value) {
            const { checkIn } = this.parent;
            const start = new Date(checkIn);
            const end = new Date(value);
            return end > start;
          }
        ),
    }),

    onSubmit: async (values) => {
      if (!signed) {
        toastAlert("error", "Please sign before saving");
        return null;
      }

      const file = base64toImageFile(signed);
      let formData = new FormData();
      formData.append("file", file, "signature.png");

      const imageUpload = await uploadSignFile(formData);
      if (imageUpload.status !== 200) {
        toastAlert("error", "error uploading signature file");
        return null;
      }
      let body = {
        clientId: values?.clientId?.value,
        shiftDate: moment(values?.date)?.format("YYYY-MM-DD"),
        note: values?.note?.trim() !== "" ? values?.note : " ",
        checkIn: moment(values.checkIn)?.format("HH:mm"),
        checkOut: moment(values.checkOut)?.format("HH:mm"),
        signature: imageUpload.data.data.fileUrl,
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => addPayout(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate(-1);
    },
  });

  const getClients = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.CLIENT,
    };
    if (search) params.search = search;
    let resp = await getUserByEvents(params);
    let array = (await resp?.data?.data) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i?.userDetails?.firstName} ${i?.userDetails?.lastName}`,
        value: i?.userDetails?._id,
      })),
      hasMore: false,
      additional: {
        page: 1,
      },
    };
  };
  const getEventsList = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      clientId: values?.clientId?.value,
      previousEvents: "true",
    };
    if (search) params.search = search;
    let resp = await getEvents(params);
    let array = (await resp?.data?.data?.events) ?? [];
    return {
      options: array.map((i) => {
        const startTime = moment(i.startTime);
        const endTime = moment(i.endTime);
        const labelValue = `${startTime.format("ll")} (${startTime.format(
          constant.HOURS_FORMAT
        )} - ${endTime.format(constant.HOURS_FORMAT)})`;
        return {
          label: labelValue,
          value: i._id,
        };
      }),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const parseTimeInput = (input, shiftDate = new Date()) => {
    let hours,
      minutes = 0;
    const isPM = /pm$/i.test(input);
    const isAM = /am$/i.test(input);

    const timeString = input.replace(/(am|pm)/i, "").trim();
    if (timeString.length === 1) {
      hours = parseInt(timeString[0], 10);
    } else if (timeString.length === 2) {
      hours = parseInt(timeString, 10);
    } else if (timeString.length === 3) {
      hours = parseInt(timeString[0], 10);
      minutes = parseInt(timeString.slice(1), 10);
    } else if (timeString.length === 4) {
      hours = parseInt(timeString.slice(0, 2), 10);
      minutes = parseInt(timeString.slice(2), 10);
    } else {
      return null;
    }

    if (isPM && hours < 12) hours += 12;
    if (isAM && hours === 12) hours = 0;

    const resultDate = new Date(shiftDate || Date.now());
    resultDate.setHours(hours, minutes, 0, 0);

    return resultDate;
  };

  const handleClear = () => {
    setSigned(null);
    if (signRef.current) signRef.current.clear();
    else console.log("Ref is not working for signature canvas");
  };

  const saveSignature = () => {
    if (signRef && signRef.current) {
      const isEmpty = signRef.current.isEmpty();
      if (isEmpty) {
        toastAlert("error", "Please sign before saving");
        return null;
      }
      const signurl = signRef.current.getTrimmedCanvas().toDataURL("image/png");
      setSigned(signurl);
    } else toastAlert("error", "Some error occured with signature canvas");
  };

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <div className="addHeader mb-3">
            <span className="heading20">Time sheet</span>
          </div>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">
                  <div className="col-md-12 mb-3 ">
                    <div className="form-group">
                      <label className="labelTxt">
                        Client <span className="text-danger">*</span>
                      </label>
                      <AsyncPaginate
                        additional={{
                          page: 1,
                        }}
                        styles={constant.REACT_SELECT_CUSTOM_STYLE}
                        debounceTimeout={500}
                        loadOptions={getClients}
                        value={values?.clientId}
                        onChange={(e) => {
                          setFieldValue("clientId", e);
                        }}
                        onBlur={() => setFieldTouched("clientId", true)}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.clientId && errors?.clientId?.value}
                      </small>
                    </div>
                  </div>
                  {values?.clientId && (
                    <div className="col-md-12 mb-3">
                      <div className="form-group position-relative">
                        <label className="labelTxt">
                          Shift Date<span className="text-danger">*</span>
                        </label>
                        <img
                          src={images.calendar}
                          className="calanderIcon"
                          alt="calendarImg"
                          role="button"
                          onClick={() => dateRef.current.setFocus()}
                        />

                        <DatePicker
                          selected={values?.date}
                          onChange={(date) => setFieldValue("date", date)}
                          onBlur={() => setFieldTouched("date", true)}
                          className="inputBox"
                          ref={dateRef}
                        />
                      </div>
                      <small className="text-danger requiredTxt">
                        {touched.date && errors.date}
                      </small>
                    </div>
                  )}

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-In
                        <span className="text-danger">*</span>
                      </label>

                      <DatePicker
                        selected={values?.checkIn}
                        onChange={(date) => {
                          if (date && values?.date) {
                            const combinedDate = new Date(values.date);
                            combinedDate.setHours(date.getHours());
                            combinedDate.setMinutes(date.getMinutes());
                            combinedDate.setSeconds(0, 0);
                            setFieldValue("checkIn", combinedDate);
                          }
                        }}
                        onChangeRaw={(e) => {
                          const input = e.target.value.trim();
                          if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                            const parsedDate = parseTimeInput(
                              input,
                              values?.date
                            );
                            if (parsedDate) {
                              setFieldValue("checkIn", parsedDate);
                            }
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                        onBlur={() => setFieldTouched("checkIn", true)}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.checkIn && errors.checkIn}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Check-Out
                        <span className="text-danger">*</span>
                      </label>

                      <DatePicker
                        selected={values?.checkOut}
                        onChange={(date) => {
                          if (date && values?.date) {
                            const combinedDate = new Date(values.date);
                            combinedDate.setHours(date.getHours());
                            combinedDate.setMinutes(date.getMinutes());
                            combinedDate.setSeconds(0, 0);
                            setFieldValue("checkOut", combinedDate);
                          }
                        }}
                        onChangeRaw={(e) => {
                          const input = e.target.value.trim();
                          if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                            const parsedDate = parseTimeInput(
                              input,
                              values?.date
                            );
                            if (parsedDate) {
                              setFieldValue("checkOut", parsedDate);
                            }
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="inputBox"
                        onBlur={() => setFieldTouched("checkOut", true)}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.checkOut && errors.checkOut}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">Note</label>
                      <textarea
                        rows={3}
                        name="note"
                        className="inputBox"
                        value={values?.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.note && errors.note}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className="labelTxt">Client Initials</label>

                    <SignatureCanvas
                      ref={signRef}
                      penColor="green"
                      canvasProps={{
                        width: 600,
                        height: 200,
                        className: "sigCanvas",
                      }}
                      onEnd={saveSignature}
                    />

                    {
                      <div className="d-flex align-items-center gap-3 ">
                        <button
                          onClick={handleClear}
                          type="button"
                          className="cancleBtn"
                        >
                          Clear
                        </button>
                      </div>
                    }
                  </div>
                  <div className="col-md-12 ">
                    <p className="text-danger requiredTxt">
                      <b>Note:</b> Once you've submitted the payout details,
                      they can't be changed. Make sure to review them carefully
                      before submitting!
                    </p>
                  </div>
                </div>
              </div>
              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Submit
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
    </>
  );
};

export default AddPayOut;
