import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useRef } from "react";
import { Card, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import NavigateBack from "../../common/NavigateBack";
import useDetails from "../../hooks/useDetails";
import useRole from "../../hooks/useRole";
import { addTimeOff, getUsers } from "../../services/services";
import { constant } from "../../utils/constants";
import { setTimeFromString } from "../../utils/function";
import { toastAlert } from "../../utils/SweetAlert";

const   AddTimeOff = () => {
  const dateRef = useRef();
  const navigate = useNavigate();
  const details = useDetails();
  const role = useRole();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      date: "",
      fullDay: false,
      startTime: "",
      endTime: "",
      reason: "",
      employeeId: "",
    },
    validationSchema: yup.object().shape({
      date: yup.date().required().label("Time off date"),
      employeeId: yup.object().when("role", {
        is: () => role == constant.ROLE.ADMIN,
        then: () =>
          yup.object().shape({
            value: yup.string().required().label("Employee"),
          }),
      }),
      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-greater-than-current",
          "Start time should be later than the current time",
          function (value) {
            const { date } = this.parent;
            const startTime = new Date(date);
            setTimeFromString(startTime, value);
            return startTime >= new Date();
          }
        ),
      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-greater-than-startTime",
          "End time should be later than start time",
          function (value) {
            const { date, startTime } = this.parent;
            if (!startTime) {
              return true;
            }
            const start = new Date(date);
            const end = new Date(date);
            setTimeFromString(start, startTime);
            setTimeFromString(end, value);
            return end > start;
          }
        ),
      reason: yup.string().required().label("Reason").trim().min(2),
    }),
    onSubmit: async (values) => {
      const start = new Date(values?.date);
      setTimeFromString(start, values?.startTime);``
      const end = new Date(values?.date);
      setTimeFromString(end, values?.endTime);
      let body = {
        date: moment(values?.date).format(constant.DATE_FORMAT),
        fullDay: values?.fullDay,
        reason: values?.reason,
        employeeId:
          role == constant.ROLE.EMPLOYEE
            ? details?._id
            : values?.employeeId?.value,
        startTime: moment(start, "HH:mm").format(constant.DATE_FORMAT),
        endTime: moment(end, "HH:mm").format(constant.DATE_FORMAT),
      };

      mutation.mutate(body);
    },
  });

  const employeeList = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.EMPLOYEE,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const mutation = useMutation({
    mutationFn: async (body) => addTimeOff(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../time-off");
    },
  });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <section className="main-content">
        <div className="addclientPage">
          <NavigateBack>Add Time Off Request</NavigateBack>
          <Card border="light" bg="light" className="p-4 w-50 mx-auto">
            <Form onSubmit={handleSubmit}>
              <div className="clientInputBox ">
                <div className="row ">
                  {role && role === constant.ROLE.ADMIN && (
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="labelTxt">Employee</label>
                        <AsyncPaginate
                          additional={{
                            page: 1,
                          }}
                          styles={constant.REACT_SELECT_CUSTOM_STYLE}
                          debounceTimeout={500}
                          loadOptions={employeeList}
                          value={values?.employeeId}
                          onChange={(e) => setFieldValue("employeeId", e)}
                        />

                        <small className="text-danger requiredTxt">
                          {touched.employeeId && errors.employeeId?.value}
                        </small>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 mb-3">
                    <div className="form-group position-relative">
                      <label className="labelTxt">
                        Time Off Date <span className="text-danger">*</span>
                      </label>
                      <img
                        src={images.calendar}
                        className="calanderIcon"
                        alt="calendarImg"
                        role="button"
                        onClick={() => dateRef.current.setFocus()}
                      />
                      <DatePicker
                        selected={values?.date}
                        onChange={(date) => setFieldValue("date", date)}
                        onBlur={() => setFieldTouched("date", true)}
                        className="inputBox"
                        minDate={tomorrow}
                        ref={dateRef}
                      />
                    </div>
                    <small className="text-danger requiredTxt">
                      {touched.date && errors.date}
                    </small>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Check
                      type="checkbox"
                      id="custome-time"
                      label="Full day off"
                      name="fullDay"
                      value={values?.fullDay}
                      checked={values?.fullDay}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setFieldValue("startTime", "00:00");
                          setFieldValue("endTime", "23:59");
                          setFieldTouched("startTime", false);
                          setFieldTouched("endTime", false);
                        } else {
                          setFieldValue("startTime", "");
                          setFieldTouched("startTime", false);
                          setFieldValue("endTime", "");
                          setFieldTouched("endTime", false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Start Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="startTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.startTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.startTime && errors.startTime}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        End Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name="endTime"
                        className="timePicker inputBox"
                        disabled={values?.fullDay}
                        value={values?.endTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.endTime && errors.endTime}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <label className="labelTxt">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={3}
                        name="reason"
                        className="inputBox"
                        value={values?.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <small className="text-danger requiredTxt">
                        {touched.reason && errors.reason}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="staffBtn mt-4">
                <button className="addBtn me-3" type="submit">
                  Add
                </button>
                <Link className="cancleBtn" role="button" to={-1}>
                  Cancel
                </Link>
              </div>
            </Form>
          </Card>
        </div>
      </section>
    </>
  );
};

export default AddTimeOff;
