import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from "sweetalert2";
import * as yup from "yup";
import * as images from "../../assets/image";
import { addEvent, getUsers, updateEvent } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import { MdVerified } from "react-icons/md";

const AddEvent = ({ show, setShow, refetch }) => {
  const dateRef = useRef();
  const endDateRef = useRef();

  const [editing, setEditing] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditing(false);
    resetForm();
    refetch();
  };

  let currentDate = new Date();
  let endDateReoccurringEvent = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      id: "",
      employeeId: "",
      shiftDate: new Date(),
      startTime: "",
      endTime: "",
      clientId: "",
      note: "",
      sync: false,
      reoccurring: false,
      repeatOn: [],
      endsOn: endDateReoccurringEvent,
    },
    validationSchema: yup.object().shape({
      shiftDate: yup.date().required().label("Shift date"),
      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-greater-than-current",
          "Start time should be later than the current time",
          function (value) {
            let startTime = new Date(value);
            return startTime >= new Date();
          }
        ),
      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-greater-than-startTime",
          "End time should be later than start time",
          function (value) {
            const { startTime } = this.parent;
            const start = new Date(startTime);
            const end = new Date(value);
            return end > start;
          }
        ),
      clientId: yup.object().shape({
        value: yup.string().required().label("Client"),
      }),
      repeatOn: yup.array().when("reoccurring", {
        is: "true",
        then: () => yup.array().min(1).label("Repeat on"),
      }),
      endsOn: yup.string().when("reoccurring", {
        is: "true",
        then: () =>
          yup
            .string()
            .required()
            .label("End on")
            .nullable()
            .test(
              "is-greater-than-startTime",
              "End On should be later than start date",
              function (value) {
                const { shiftDate } = this.parent;
                const start = new Date(shiftDate);
                start.setHours(0, 0, 0, 0);
                const end = new Date(value);
                end.setHours(0, 0, 0, 0);
                return start < end;
              }
            ),
      }),
    }),

    onSubmit: async (values) => {
      let body = {
        override: false,
        clientId: values.clientId?.value,
        employeeId: values?.employeeId?.value || null,
        note: values?.note?.trim() || "",
        startTime: moment(values?.startTime).format(constant.DATE_FORMAT),
        endTime: moment(values?.endTime).format(constant.DATE_FORMAT),
        isReoccurring: values?.reoccurring == "true" ? true : false,
      };
      if (values.id) body.id = values.id;
      if (values?.reoccurring == "true") {
        body.repeatOn = values.repeatOn;
        body.endsOn = moment(values.endsOn).format(constant.DATE_FORMAT);
      }

      mutation.mutate(body);
    },
  });



  const mutation = useMutation({
    mutationFn: async (body) => (body.id ? updateEvent(body) : addEvent(body)),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      handleClose();
    },
    onError: (error) => {
      toastAlert("error", error.response?.data?.message);
      if (error.status == 422) {
        return overrideEmployeeAssign(error.response.data)
      }
    }
  });

  const overrideEmployeeAssign = (responseData) => {
    let reason = responseData?.data?.reason ? responseData?.data?.reason : responseData?.message
    Swal.fire({
      title: "Are you sure?",
      text: `You are going to assign this event as ${reason?.toLowerCase()} `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, override!",
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          override: true,
          clientId: values.clientId?.value,
          employeeId: values?.employeeId?.value || null,
          note: values?.note?.trim() || "",
          startTime: moment(values?.startTime).format(constant.DATE_FORMAT),
          endTime: moment(values?.endTime).format(constant.DATE_FORMAT),
          isReoccurring: values?.reoccurring == "true" ? true : false,
        };
        if (values.id) body.id = values.id;
        if (values?.reoccurring == "true") {
          body.repeatOn = values.repeatOn;
          body.endsOn = moment(values.endsOn).format(constant.DATE_FORMAT);
        }

        mutation.mutate(body);
      }
    });
  };

  useEffect(() => {
    let data = show?.event?.extendedProps;
    if (data) {
      setValues((prevValues) => ({
        ...prevValues,
        id: data?._id,
        employeeId: data?.employeeId
          ? {
              value: data?.employeeId,
              label: `${data?.employeeDetails?.firstName || ""} ${
                data?.employeeDetails?.lastName || ""
              }`,
            }
          : "",
        shiftDate: new Date(data?.startTime),
        startTime: new Date(data?.startTime),
        endTime: new Date(data?.endTime),
        clientId: {
          value: data?.clientId,
          label: `${data.clientDetails.firstName} ${data.clientDetails.lastName}`,
          authorizedEmployees: data?.clientDetails?.authorizedEmployees || [],
        },
        note: data?.note || "",
        sync: data?.sync,
        reoccurring: `${data.isReoccurring}`,
        repeatOn: data.repeatOn,
        endsOn: new Date(data?.endsOn),
      }));
      setEditing(true);
    }

    if (show?.date) {
      setFieldValue("shiftDate", new Date(show?.date));
      setFieldValue("startTime", new Date(show?.date));
    }
  }, [show?.event?.extendedProps?.id || show?.date]);

  const parseTimeInput = (input, shiftDate) => {
    let hours,
      minutes = 0;
    const isPM = /pm$/i.test(input);
    const isAM = /am$/i.test(input);

    const timeString = input.replace(/(am|pm)/i, "").trim();

    if (timeString.length === 1) {
      hours = parseInt(timeString[0], 10);
    } else if (timeString.length === 2) {
      hours = parseInt(timeString, 10);
    } else if (timeString.length === 3) {
      hours = parseInt(timeString[0], 10);
      minutes = parseInt(timeString.slice(1), 10);
    } else if (timeString.length === 4) {
      hours = parseInt(timeString.slice(0, 2), 10);
      minutes = parseInt(timeString.slice(2), 10);
    } else {
      return null;
    }

    if (isPM && hours < 12) hours += 12;
    if (isAM && hours === 12) hours = 0;

    const resultDate = new Date(shiftDate || Date.now());
    resultDate.setHours(hours, minutes, 0, 0);

    return resultDate;
  };

  const getClients = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.CLIENT,
      sortedBy: constant.SORTED_BY.FIRST_NAME,
      sortBy: constant.SORT_BY.ASC,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
        authorizedEmployees: i.authorizedEmployees,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const getEmployee = async (search, loadedOptions, { page }) => {
    let params = {
      page: page,
      limit: constant.PAGE_LIMIT,
      role: constant.ROLE.EMPLOYEE,
      sortedBy: constant.SORTED_BY.FIRST_NAME,
      sortBy: constant.SORT_BY.ASC,
    };
    if (search) params.search = search;
    let resp = await getUsers(params);
    let array = (await resp?.data?.data?.users) ?? [];

    return {
      options: array.map((i) => ({
        label: `${i.firstName} ${i.lastName}`,
        value: i._id,
        authorized: values?.clientId?.authorizedEmployees?.includes(i?._id)
          ? true
          : false,
      })),
      hasMore: resp?.data?.data?.total_pages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleEmployeeSelection = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to assign this event to an unauthorized employee!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, assign it!",
    }).then((result) => {
      if (result.isConfirmed) setFieldValue("employeeId", e);
    });
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    const optionStyles = {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      backgroundColor: isSelected
        ? "#2684ff"
        : isFocused
          ? "#e6f0ff"
          : "transparent",
      color: isSelected ? "#fff" : "inherit",
      cursor: "pointer",
      borderRadius: "4px",
    };

    return (
      <div ref={innerRef} {...innerProps} style={optionStyles}>
        <span>{data.label}</span>
        {data?.authorized && (
          <MdVerified
            color="green"
            style={{ marginLeft: "8px", fontSize: "20px" }}
          />
        )}
      </div>
    );
  };

  

  return (
    <Modal
    show={!!show}
    onHide={handleClose}
    centered
    className="addModal"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {!!values?.id ? "Update Schedule" : "Add Schedule"}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(() => {
       const isEditingDisabled = (() => {
        if (show?.event?.start) {
          const eventStart = new Date(show.event.start);
          const now = new Date();
          // Create a Date object for today's date at midnight (00:00:00) for comparison
          const todayStart = new Date(now);
          todayStart.setHours(0, 0, 0, 0);
          // If the event's start date is before today, disable editing
          if (eventStart < todayStart) {
            return true; // Disable editing for past events
          }
          // If the event's start date is today, allow editing
          if (eventStart.toDateString() === now.toDateString()) {
            return false; // Allow editing for today's event
          }
          return false; // Allow editing if the event is in the future
        }
        return false; // Return false if event.start is not defined
      })();
      
  const isEditing=values.id?true:false
        return (
          <div className="row">
            {/* Client Field */}
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label className="labelTxt">
                  Client <span className="text-danger">*</span>
                </label>
                <AsyncPaginate
                  additional={{
                    page: 1,
                  }}
                  styles={constant.REACT_SELECT_CUSTOM_STYLE}
                  debounceTimeout={500}
                  loadOptions={getClients}
                  value={values?.clientId}
                  onChange={(e) => setFieldValue("clientId", e)}
                  onBlur={() => setFieldTouched("clientId", true)}
                  isDisabled={isEditingDisabled}
                />
                <small className="text-danger requiredTxt">
                  {touched.clientId && errors.clientId?.value}
                </small>
              </div>
            </div>
  
            {/* Employee Field */}
            {values.clientId && values.clientId !== "" && (
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label className="labelTxt">Employee</label>
                  <AsyncPaginate
                    additional={{
                      page: 1,
                    }}
                    isClearable={true}
                    styles={constant.REACT_SELECT_CUSTOM_STYLE}
                    debounceTimeout={500}
                    loadOptions={getEmployee}
                    components={{ Option: CustomOption }}
                    value={values?.employeeId}
                    onChange={(e) => {
                      if (
                        e?.value &&
                        !values?.clientId?.authorizedEmployees?.includes(e?.value)
                      ) {
                        handleEmployeeSelection(e);
                      } else {
                        setFieldValue("employeeId", e);
                      }
                    }}
                    onBlur={() => setFieldTouched("employeeId", true)}
                    isDisabled={isEditingDisabled}
                  />
                  <small className="text-danger requiredTxt">
                    {touched.employeeId && errors.employeeId}
                  </small>
                </div>
              </div>
            )}
  
            {/* Shift Date */}
            <div className="col-md-12 mb-3">
              <div className="form-group position-relative">
                <label className="labelTxt">
                  Shift Date <span className="text-danger">*</span>
                </label>
                <img
                  src={images.calendar}
                  className="calanderIcon"
                  alt="calendarImg"
                  role="button"
                  onClick={() => dateRef?.current?.setFocus()}
                />
                <DatePicker
                  onChange={(date) => {
                    setFieldValue("shiftDate", date);
                    if (date && values.startTime) {
                      const combinedDate = new Date(date);
                      combinedDate.setHours(values.startTime.getHours());
                      combinedDate.setMinutes(values.startTime.getMinutes());
                      combinedDate.setSeconds(0, 0);
                      setFieldValue("startTime", combinedDate);
                    }
                    if (date && values.endTime) {
                      const combinedDate = new Date(date);
                      combinedDate.setHours(values.endTime.getHours());
                      combinedDate.setMinutes(values.endTime.getMinutes());
                      combinedDate.setSeconds(0, 0);
                      setFieldValue("endTime", combinedDate);
                    }
                  }}
                  className="inputBox"
                  selected={values?.shiftDate}
                  minDate={new Date()}
                  ref={dateRef}
                  onBlur={() => setFieldTouched("shiftDate", true)}
                  disabled={isEditingDisabled}
                />
                <small className="text-danger requiredTxt">
                  {touched.shiftDate && errors.shiftDate}
                </small>
              </div>
            </div>
  
            {/* Start and End Time */}
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label className="labelTxt">
                  Scheduled Start Time <span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={values?.startTime}
                  onChange={(date) => {
                    if (date && values?.shiftDate) {
                      const combinedDate = new Date(values.shiftDate);
                      combinedDate.setHours(date.getHours());
                      combinedDate.setMinutes(date.getMinutes());
                      combinedDate.setSeconds(0, 0);
                      setFieldValue("startTime", combinedDate);
                    }
                  }}
                  onChangeRaw={(e) => {
                    const input = e.target.value.trim();
                    if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                      const parsedDate = parseTimeInput(
                        input,
                        values?.shiftDate
                      );
                      if (parsedDate) {
                        setFieldValue("startTime", parsedDate);
                      }
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="inputBox"
                  onBlur={() => setFieldTouched("startTime", true)}
                  disabled={isEditingDisabled}
                />
                <small className="text-danger requiredTxt">
                  {touched.startTime && errors.startTime}
                </small>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label className="labelTxt">
                  Scheduled End Time <span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={values?.endTime}
                  onChange={(date) => {
                    if (date && values?.shiftDate) {
                      const combinedDate = new Date(values.shiftDate);
                      combinedDate.setHours(date.getHours());
                      combinedDate.setMinutes(date.getMinutes());
                      combinedDate.setSeconds(0, 0);
                      setFieldValue("endTime", combinedDate);
                    }
                  }}
                  onChangeRaw={(e) => {
                    const input = e.target.value.trim();
                    if (/^\d{1,4}([ap]m)?$/i.test(input)) {
                      const parsedDate = parseTimeInput(
                        input,
                        values?.shiftDate
                      );
                      if (parsedDate) {
                        setFieldValue("endTime", parsedDate);
                      }
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="inputBox"
                  onBlur={() => setFieldTouched("endTime", true)}
                  disabled={isEditingDisabled}
                />
                <small className="text-danger requiredTxt">
                  {touched.endTime && errors.endTime}
                </small>
              </div>
            </div>  
            {/* Reoccurring Events */}
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label className="labelTxt">Reoccurring Events</label>
                <select
                  className="inputBox"
                  name="reoccurring"
                  disabled={isEditing}
                  value={values?.reoccurring}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
            </div>
            {values?.reoccurring == "true" && (
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label className="labelTxt">
                    Repeat on <span className="text-danger">*</span>
                  </label>
                  <Row>
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                      (day, index) => (
                        <Col md={4} key={index}>
                          <div className="days">
                            <input
                              type="checkbox"
                              id={day}
                              name="repeatOn"
                              value={index}
                              disabled={isEditing}
                              onChange={handleChange}
                              checked={values?.repeatOn?.includes(index.toString())}
                            />
                            <label className="daysLabel" htmlFor={day}>
                              {day}
                            </label>
                          </div>
                        </Col>
                      )
                    )}
                    <small className="text-danger requiredTxt">
                      {touched.repeatOn && errors.repeatOn}
                    </small>
                  </Row>
                </div>
                <div className="form-group">
                  <label className="labelTxt">
                    Ends On <span className="text-danger">*</span>
                  </label>
                  <div className="form-group position-relative">
                    <img
                      src={images.calendar}
                      className="calanderIcon"
                      alt="calendarImg"
                    />
                    <DatePicker
                      onChange={(date) => setFieldValue("endsOn", date)}
                      className="inputBox"
                      disabled={isEditing}
                      selected={values?.endsOn}
                      minDate={new Date()}
                      ref={endDateRef}
                      onBlur={() => setFieldTouched("endsOn", true)}
                    />
                  </div>
                  <small className="text-danger requiredTxt">
                    {touched.endsOn && errors.endsOn}
                  </small>
                </div>
              </div>
            )}
  
            {/* Note */}
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label className="labelTxt">Note</label>
                <textarea
                  rows={3}
                  name="note"
                  className="inputBox"
                  value={values?.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEditingDisabled}
                />
                <small className="text-danger requiredTxt">
                  {touched.note && errors.note}
                </small>
              </div>
            </div>
  
            {/* Buttons */}
            <div className="d-flex align-items-center gap-3">
              {!isEditingDisabled && (
                <button
                  className="greenBtn"
                  type="button"
                  onClick={handleSubmit}
                >
                  Save Changes
                </button>
              )}
              <button onClick={handleClose} type="button" className="cancleBtn">
                Close
              </button>
            </div>
          </div>
        );
      })()}
    </Modal.Body>
  </Modal>
  
  );
};

export default AddEvent;
