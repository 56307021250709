import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaKey, FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as images from "../assets/image";
import useDetails from "../hooks/useDetails";
import useRole from "../hooks/useRole";
import { login } from "../redux/features/authSlice";
import { logOut } from "../services/services";
import { constant } from "../utils/constants";
import Loader from "./loader/Loader";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const details = useDetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useRole();

  const logoutMutation = useMutation({
    mutationFn: () => logOut(),
    onSuccess: () => {
      dispatch(login({}));
    },
  });

  const handleNotificationBtn = () => {
    if(role===constant.ROLE.ADMIN||role===constant.ROLE.EMPLOYEE){
      navigate(`/${role}/notifications`);
    }
    else{
      navigate(`/notifications`);

    }
  
  };

  return (
    <>
      <header
        className={
          "header"
        }
      >
     
        <h3 className="headerHeading">{/* <IoReorderThree size={30} /> */}</h3>

        <div className="headerRight">
       <div
            className="navNotification position-relative"
            onClick={handleNotificationBtn}
          >
            <img
              src={images.notificationIcon}
              alt="image"
              className="img-fluid"
            />
            <div className="notificationBadge"></div>

          </div>
          <div className="profileArea">
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <span className="img-ic">
                  <img
                    src={
                      details?.profilePhoto
                        ? details?.profilePhoto
                        : images.profile
                    }
                    alt="Image"
                    className="me-3 profileImg"
                  />
                </span>
                <label className="text-capitalize">{`${details?.firstName} ${details?.lastName}`}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/profile"
                        : role == constant.ROLE.EMPLOYEE
                        ? "/employee/profile"
                        : "/profile"
                    )
                  }
                >
                  <FaUser className="me-2" />
                  Profile
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      role == constant.ROLE.ADMIN
                        ? "/admin/change-password"
                        : role == constant.ROLE.EMPLOYEE
                        ? "/employee/change-password"
                        : "/change-password"
                    )
                  }
                >
                  <FaKey className="me-2" />
                  Change Password
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    logoutMutation.mutate();
                  }}
                >
                  <FiLogOut className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
